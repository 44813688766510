<template>
  <div class="auto_css" id="myElement">
    <div class="section1 section" id="section1">
      <img class="aboutbg1 pc" :src="require('@/assets/img/autobg1.png')" />
      <img class="aboutbg1 h5" :src="require('@/assets/imgh5/autobg1.png')" />

      <div class="top-font">人才招聘</div>
      <div class="ct-font">
        <div>YUCHUANG</div>
        <div>CENTURY</div>
      </div>
    </div>
    <div class="section2 section" id="section2">
      <img class="aboutbg2 pc" :src="require('@/assets/img/joinbg2.png')" />
      <img class="aboutbg2 h5" :src="require('@/assets/imgh5/joinbg2.png')" />

      <!-- 职位列表  -->
      <div class="j-contain" v-if="showDiv === 1">
        <!-- 搜索框 -->
        <div class="input-item">
          <input
            type="text"
            class="s-input"
            v-model="searchText"
            placeholder="输入关键词搜索职位"
          />
          <img
            class="s-icon"
            @click="getJoinList"
            :src="require('@/assets/img/s-icon.png')"
          />
        </div>

        <!-- 职位列表 -->
        <div class="job-item">
          <!-- 表头 -->
          <div class="t-header">
            <div class="t-item">职位名称</div>
            <div class="t-item">职位类型</div>
            <div class="t-item">工作性质</div>
            <div class="t-item">更多</div>
          </div>
          <!-- 表内容 -->
          <div
            class="tip-text"
            v-if="!tableList.length || tableList.length == 0"
          >
            这里暂时还没有内容噢~
          </div>
          <div v-else class="t-body-all" id="tablebody">
            <div class="t-body" v-for="(item, index) in tableList" :key="index">
              <div class="t-item">{{ item.job_name }}</div>
              <div class="t-item">{{ item.job_type }}</div>
              <div class="t-item">{{ item.job_attr }}</div>
              <div class="t-item">
                <span style="cursor: pointer" @click="hanleGoDetail(item)"
                  >查看详情 ></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 职位详情 -->
      <div class="j-contain j-contain2" v-if="showDiv === 2">
        <!-- 返回按钮 -->
        <div class="b-btn" @click="handleBack">&lt; 返回</div>
        <!-- 岗位信息 -->
        <div class="job-top">
          <div class="j-name">{{ detail.job_name }}</div>
          <div class="j-type">
            {{ detail.job_type }}<span style="margin: 0 20px">|</span
            >{{ detail.job_attr }}
          </div>
        </div>
        <!-- 岗位详情 -->
        <div class="j-detail" id="jobdetail">
          <div class="title">职位描述</div>
          <div class="d-text" v-html="detail.job_desc"></div>
          <div class="title">职位要求</div>
          <div class="d-text" v-html="detail.job_request"></div>
          <div class="title">工作地点</div>
          <div class="d-text">{{ detail.job_address }}</div>
          <div class="title">联系方式</div>
          <div class="d-text">{{ detail.job_tel }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, computed, watch, getCurrentInstance, onMounted } from "vue";
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
const { proxy } = getCurrentInstance();
// page几
const $index1 = computed(() =>
  proxy.$route.query.page ? Number(proxy.$route.query.page) : 1
);
const $index = ref($index1.value);
watch($index1, (newV) => {
  $index.value = newV;
});
// 搜索
const showDiv = ref(1);
const searchText = ref("");
const tableList = ref([]);
const webId = ref();
console.log(window.location.host);
// 详情
const detail = ref();

// 初始化
const initData = async () => {
  let params = {
    host: window.location.host, //"www.yucsj.com",
  };
  proxy.$axios.post("/Index/initData", params).then(async (res) => {
    if (res.code == 200) {
      webId.value = res.data.company_id;
      getJoinList();
    }
  });
};
initData();
// 获取招聘列表
const getJoinList = () => {
  let params = {
    company_id: webId.value,
    job_name: searchText.value,
  };
  proxy.$axios.post("/Index/getJobs", params).then(async (res) => {
    if (res.code == 200) {
      tableList.value = res.data;
    }
  });
};
// 查看详情
const hanleGoDetail = (item) => {
  detail.value = item;
  showDiv.value = 2;
};
const handleBack = () => {
  showDiv.value = 1;
};
onMounted(async () => {
  store.commit("setLoad", false);
});
</script>
<style src="./join.css" scoped></style>
<style scoped>
</style>
  