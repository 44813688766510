<template>
  <div class="affix-container pc">
    <el-menu
      :default-active="activeIndex"
      class="el-menu"
      mode="horizontal"
      :ellipsis="false"
      @select="handleSelect"
    >
      <el-menu-item class="logobtn" index="1">
        <el-image
          class="imgw145"
          style="width: 40%"
          :src="require('@/assets/img/logo.png')"
        />
      </el-menu-item>
      <div class="flex-grow" />
      <el-menu-item index="1">
        首页
        <img
          v-show="activeIndex == '1'"
          class="menu-index"
          :src="require('@/assets/img/menuindex.png')"
        />
      </el-menu-item>
      <el-menu-item index="2" style="position: relative" class="about-menu">
        <div>关于我们</div>

        <div class="menu-nav" v-if="href != '/auto'">
          <el-image
            class="menu-bug"
            :src="require('@/assets/img/menunav.png')"
          />

          <div class="nav-ul">
            <div class="nav-li" @click.stop="handleSetType(1)">公司简介</div>
            <!-- <div class="nav-li" @click.stop="handleSetType(2)">企业文化</div> -->
            <div class="nav-li" @click.stop="handleSetType(3)">发展历程</div>
          </div>
        </div>

        <img
          v-show="activeIndex == '2'"
          class="menu-index"
          :src="require('@/assets/img/menuindex.png')"
        />
      </el-menu-item>
      <el-menu-item index="3"
        >游戏产品
        <img
          v-show="activeIndex == '3'"
          class="menu-index"
          :src="require('@/assets/img/menuindex.png')"
        />
      </el-menu-item>
      <el-menu-item index="4"
        >人才招聘
        <img
          v-show="activeIndex == '4'"
          class="menu-index"
          :src="require('@/assets/img/menuindex.png')"
        />
      </el-menu-item>
    </el-menu>
  </div>
  <!-- H5 顶部 -->
  <div class="h5-top h5">
    <img class="top-icon" :src="require('@/assets/imgh5/logo.png')" />
    <img
      class="top-more"
      @click="handleShowMenu"
      :src="require('@/assets/imgh5/more.png')"
    />

    <!-- h5菜单 -->
    <div
      class="menu-zhe"
      v-if="menuShow"
      @click="
        () => {
          menuShow = false;
        }
      "
    >
      <div class="h5-menu">
        <img class="h5-m-bg" :src="require('@/assets/imgh5/menubg.png')" />

        <div
          class="menu-li"
          :class="activeIndex === '1' ? 'active-li' : ''"
          @click.stop="handleSelect('1')"
        >
          首页
          <img
            v-if="activeIndex === '1'"
            class="menu-index"
            :src="require('@/assets/imgh5/menuindex.png')"
          />
        </div>
        <div
          class="menu-li"
          style="top: 1.48rem"
          :class="activeIndex === '2' ? 'active-li' : ''"
          @click.stop="handleSelect('2')"
        >
          关于我们
          <img
            v-if="activeIndex === '2'"
            class="menu-index"
            :src="require('@/assets/imgh5/menuindex.png')"
          />
        </div>
        <div
          class="menu-li"
          style="top: 2.96rem"
          @click.stop="handleSelect('3')"
          :class="activeIndex === '3' ? 'active-li' : ''"
        >
          游戏产品
          <img
            v-if="activeIndex === '3'"
            class="menu-index"
            :src="require('@/assets/imgh5/menuindex.png')"
          />
        </div>
        <div
          class="menu-li"
          style="top: 4.44rem"
          @click.stop="handleSelect('4')"
          :class="activeIndex === '5' ? 'active-li' : ''"
        >
          人才招聘
          <img
            v-if="activeIndex === '5'"
            class="menu-index"
            :src="require('@/assets/imgh5/menuindex.png')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
const activeIndex = ref(store.state.activeIndex || "1");
const router = useRouter();
const menuShow = ref(false);
const href = computed(() => store.state.href);
const activeIndex1 = computed(() => store.state.activeIndex);
watch(activeIndex1, (newV) => {
  activeIndex.value = newV;
});
const handleShowMenu = () => {
  menuShow.value = true;
};
// 关于我们页面子菜单点击
const handleSetType = (val) => {
  menuShow.value = false;
  activeIndex.value = 2;
  store.commit("setActiveIndex", 2);
  router.push({ path: "/auto", query: { aboutType: val } });
};
// 菜单点击
const handleSelect = (key) => {
  menuShow.value = false;
  store.commit("setActiveIndex", key);
  sessionStorage.setItem("active", key);
  if (key == "1") {
    if (href.value === "/") return;
    router.push({ path: "/", query: { page: 1 } });
  } else if (key == "2") {
    if (href.value === "/auto") return;
    router.push({ path: "/auto" });
  } else if (key == "3") {
    if (href.value === "/Gamelist") return;
    router.push({ path: "/Gamelist" });
  } else if (key == "4") {
    if (href.value === "/join") return;
    router.push({ path: "/join" });
  }
};
</script>

<style scoped>
.imgw145 {
  display: flex;
  align-items: center;
}
.menu-nav {
  position: absolute;
  top: 70px;
  left: 0px;
}
.menu-bug {
  width: 100%;
}
.about-menu .menu-nav {
  display: none;
}
.about-menu:hover .menu-nav {
  display: block;
}
.nav-ul {
  position: absolute;
  left: 0;
  top: 10%;
  width: 100%;
  height: 90%;
}
.nav-li {
  height: 33%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.nav-li:hover {
  color: #24faff;
}
.menu-index {
  position: absolute;
  width: 16px;
  top: 72px;
  left: calc(50% - 8px);
}
.affix-container {
  background: url("../../src/assets/img/topline.png");
  height: 80px;
}
.el-menu {
  width: 80%;
  margin: 0 auto;
  background-color: initial;
  border-bottom: none;
  height: 80px;
}
.flex-grow {
  flex-grow: 1;
}
/deep/ .el-menu--horizontal > .el-menu-item.is-active {
  color: #00a5cc !important;
}
.el-menu--horizontal > .el-menu-item {
  color: #474747;
  font-size: 20px;
}
.el-menu--horizontal > .el-sub-menu .el-sub-menu__title {
  color: #474747 !important;
}
/deep/ .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: inherit;
  color: #00a5cc;
  border-bottom: none;
}
.el-menu--horizontal > .el-sub-menu .el-sub-menu__title:hover {
  background-color: inherit;
  color: #00a5cc;
  border-bottom: none;
}
.m-1 {
  margin-top: 30px;
  width: 140px;
}
@media screen and (max-width: 750px) {
  .h5-top {
    width: 100%;
    height: 1rem;
    background: url("../../src/assets/imgh5/topline.png");
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.4rem;
    box-sizing: border-box;
  }
  .top-icon {
    width: 1.2rem;
  }
  .top-more {
    width: 0.6rem;
  }
  .menu-zhe {
    position: fixed;
    top: 0;
    left: 0;
    width: 7.5rem;
    height: 100%;
    background: #0000005e;
    z-index: 999;
  }
  .h5-menu {
    position: absolute;
    right: 0;
    top: 0;
  }
  .h5-m-bg {
    width: 3rem;
  }
  .menu-li {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1.47rem;
    line-height: 1.47rem;
    text-align: center;
    color: #474747;
    font-size: 18px;
  }
  .menu-index {
    position: absolute;
    width: 0.2rem;
    top: 0.55rem;
    left: 0;
  }
  .active-li {
    color: #00a5cc;
  }
}
</style>
