<template>
  <div class="auto_css" id="myElement">
    <div class="section1 section" id="section1">
      <img class="aboutbg1 pc" :src="require('@/assets/img/autobg1.png')" />
      <img class="aboutbg1 h5" :src="require('@/assets/imgh5/autobg1.png')" />

      <div class="top-font">游戏产品</div>
      <div class="ct-font">
        <div>YUCHUANG</div>
        <div>CENTURY</div>
      </div>
    </div>
    <div class="section2 section" id="section2">
      <img class="aboutbg2 pc" :src="require('@/assets/img/gamebg2.png')" />
      <img class="aboutbg2 h5" :src="require('@/assets/imgh5/autobg2.png')" />

      <div class="contain">
        <!-- pc -->
        <div
          v-for="(item, index) in showPcList"
          :key="index"
          class="game-item pc"
        >
          <img class="glbg" :src="require('@/assets/img/glpg.png')" />
          <div class="g-left">
            <div class="g-name">
              <div class="name">{{ item.game_name }}</div>
              <!-- <div class="time">{{ dateFormat(item.start_time + "000") }}</div> -->
            </div>
            <div class="g-text">{{ item.game_desc }}</div>
          </div>
          <!-- 下载按钮 -->
          <div class="z-btn-item" v-if="item.game_url">
            <img class="btnbg" :src="require('@/assets/img/btnbg.png')" />
            <div class="btn d-btn" @click="handleGoOffice(item)">游戏官网</div>
          </div>
          <!-- 游戏icon -->
          <img class="glicon" :src="item.game_icon" />
        </div>
        <!-- 下方切换按钮 -->
        <div class="swiper-btn">
          <img
            class="s-btn prev pc"
            :class="pcPage == 1 ? 'noneGo' : ''"
            :src="require('@/assets/img/left.png')"
            @click="handlePrev"
          />
          <img class="line1 pc" :src="require('@/assets/img/line1.png')" />
          <img
            class="s-btn next pc"
            :class="pcPage == totalPage ? 'noneGo' : ''"
            :src="require('@/assets/img/right.png')"
            @click="handleNext"
          />
        </div>

        <!-- h5  -->
        <div
          v-for="(item, index) in gameList"
          :key="index"
          class="game-item h5"
        >
          <img class="glbg" :src="require('@/assets/img/glpg.png')" />
          <div class="g-left">
            <div class="g-name">
              <div class="name">{{ item.game_name }}</div>
              <!-- <div class="time">{{ dateFormat(item.start_time + "000") }}</div> -->
            </div>
            <div class="g-text">{{ item.game_desc }}</div>
          </div>
          <!-- 下载按钮 -->
          <div class="z-btn-item" v-if="item.game_url">
            <img class="btnbg" :src="require('@/assets/img/btnbg.png')" />
            <div
              class="btn d-btn"
              v-if="item.game_url"
              @click="handleGoOffice(item)"
            >
              游戏官网
            </div>
          </div>
          <!-- 游戏icon -->
          <img class="glicon" :src="item.game_icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
// import { dateFormat } from "@/utils/dateFormat";
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
const { proxy } = getCurrentInstance();
// pc展示的游戏列表4个
const pcPage = ref(1);
const totalPage = ref(1);
const gameList = ref([]);
const showPcList = ref([]);
const webId = ref();
console.log(window.location.host);
// 初始化
const initData = async () => {
  let params = {
    host: window.location.host, //"www.yucsj.com",
  };
  proxy.$axios.post("/Index/initData", params).then(async (res) => {
    if (res.code == 200) {
      webId.value = res.data.company_id;
      getGame();
    }
  });
};
initData();
// 获取游戏轮播
const getGame = async () => {
  let params = {
    company_id: webId.value,
    type: "list",
  };
  proxy.$axios.post("/Index/getGames", params).then((res) => {
    if (res.code == 200) {
      gameList.value = res.data;
      totalPage.value = Math.ceil(gameList.value.length / 4);
      getPcList();
    }
  });
};
// pc展示哪些游戏
const getPcList = () => {
  // showPcList.value = [];
  let list = [];
  if (gameList.value[(pcPage.value - 1) * 4]) {
    list.push(gameList.value[(pcPage.value - 1) * 4]);
  }
  if (gameList.value[(pcPage.value - 1) * 4 + 1]) {
    list.push(gameList.value[(pcPage.value - 1) * 4 + 1]);
  }
  if (gameList.value[(pcPage.value - 1) * 4 + 2]) {
    list.push(gameList.value[(pcPage.value - 1) * 4 + 2]);
  }
  if (gameList.value[(pcPage.value - 1) * 4 + 3]) {
    list.push(gameList.value[(pcPage.value - 1) * 4 + 3]);
  }
  showPcList.value = list;
};
// pc下方按钮点击
const handlePrev = () => {
  if (pcPage.value == 1) return;
  pcPage.value = pcPage.value - 1;
  getPcList();
};
const handleNext = () => {
  if (pcPage.value == totalPage.value) return;
  pcPage.value = pcPage.value + 1;
  getPcList();
};
// 跳转到游戏官网
const handleGoOffice = (item) => {
  if (!item.game_url) {
    proxy.$message("敬请期待！");
    return;
  }
  window.location = item.game_url;
};
onMounted(async () => {
  store.commit("setLoad", false);
});
</script>
<style src="./game.css" scoped></style>
<style scoped>
</style>
