<template>
  <div class="home" id="myElement">
    <div class="section section1" id="section1">
      <img class="homebg1 pc" :src="require('@/assets/img/homebg1.png')" />
      <img class="homebg1 h5" :src="require('@/assets/imgh5/homebg1.png')" />

      <img class="div1 pc" :src="require('@/assets/img/div1.png')" />
      <img class="div2 pc" :src="require('@/assets/img/div2.png')" />
      <img class="div3" :src="require('@/assets/img/div3.png')" />

      <img
        class="div4 h5"
        @click="scrollToAnchor('#section2')"
        :src="require('@/assets/imgh5/downtip.png')"
      />
    </div>

    <div class="section section2" id="section2" :key="styleKey1">
      <img class="homebg2 pc" :src="require('@/assets/img/homebg2.png')" />
      <img class="homebg2 h5" :src="require('@/assets/imgh5/homebg2.png')" />

      <div class="contain2">
        <img class="c-bg pc" :src="require('@/assets/img/cbg.png')" />
        <img class="c-bg h5" :src="require('@/assets/imgh5/cbg.png')" />
        <!-- 左边 -->
        <img class="a-name" :src="require('@/assets/img/alogo.png')" />
        <div class="a-text">
          <p>
            浙江宇创世纪科技股份有限公司，于 2022
            年成功转型进军游戏领域。公司秉持创新为先、品质至上的经营理念，汇聚了行业内众多优秀的技术开发人才。
          </p>
          <p>
            短短时间内，已打造出多款爆款游戏产品， 2023
            年推出的《八荒主宰》、《傲天绝剑》，以及 2024
            年的《狂暴西游》、《齐天大战神》。这些游戏备受玩家青睐，多款产品流水过亿，展现出强大的市场竞争力和影响力。未来，宇创世纪将继续砥砺前行，为玩家带来更多精彩的游戏体验。
          </p>
        </div>
        <div class="btn a-btn" @click="handleGo('/auto', 2)">了解更多</div>

        <!-- 右边 -->
        <div class="a-pic pc">
          <img class="a-picimg" :src="require('@/assets/img/apic.png')" />
        </div>
      </div>
    </div>

    <div class="section section3" id="section3">
      <img class="homebg2 pc" :src="require('@/assets/img/homebg3.png')" />
      <img class="homebg2 h5" :src="require('@/assets/imgh5/homebg3.png')" />

      <img
        class="gameicon"
        :key="styleKey2"
        :src="require('@/assets/img/gameicon.png')"
      />
      <div class="btn g-btn" :key="styleKey2" @click="handleGo('/Gamelist', 3)">
        了解更多
      </div>

      <div class="game-div swiper-container">
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in gameList"
            :key="index"
            :id="item.id"
            class="swiper-slide"
          >
            <div class="game-ul">
              <div class="game-border">
                <img class="gamebg" :src="item.game_banner" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 下方切换按钮 -->
      <div class="swiper-btn">
        <img class="s-btn prev" :src="require('@/assets/img/left.png')" />
        <img class="line1" :src="require('@/assets/img/line1.png')" />
        <img class="s-btn next" :src="require('@/assets/img/right.png')" />
      </div>
    </div>

    <div class="section section4" id="section4" :key="styleKey3">
      <img class="homebg2 pc" :src="require('@/assets/img/homebg4.png')" />
      <img class="homebg2 h5" :src="require('@/assets/imgh5/homebg4.png')" />

      <div class="contain4">
        <img class="c-bg pc" :src="require('@/assets/img/joinbg.png')" />
        <img class="c-bg h5" :src="require('@/assets/imgh5/joinbg.png')" />

        <img
          class="joinicon"
          style="cursor: pointer"
          @click="handleGo('/join', 4)"
          :src="require('@/assets/img/joinicon.png')"
        />
        <div class="font-h5">创新为先 品质至上</div>

        <div class="font-join">为玩家带来更多精彩的游戏体验</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onUnmounted,
  getCurrentInstance,
  computed,
  watch,
} from "vue";
import Swiper from "swiper";
import "swiper/css/swiper.css";
// import { throttle } from "lodash";
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
const { proxy } = getCurrentInstance();

const canRun = ref(true);
// page几
const $index1 = computed(() =>
  proxy.$route.query.page ? Number(proxy.$route.query.page) : 1
);
const $index = ref($index1.value);
watch($index1, (newV) => {
  $index.value = newV;
});
// 动画重载
const scrollPosition1 = ref(0);
const styleKey1 = ref(0);
const styleKey2 = ref(0);
const styleKey3 = ref(0);
// 初始化数据
const webId = ref();
const gameList = ref([]);
console.log(window.location.host);
// 初始化
const initData = async () => {
  return new Promise((reslove) => {
    let params = {
      host: window.location.host, //"www.yucs.net",
    };
    proxy.$axios.post("/Index/initData", params).then(async (res) => {
      if (res.code == 200) {
        webId.value = res.data.company_id;
        await getGame();
        reslove();
      }
    });
  });
};
// 获取游戏轮播
const getGame = async () => {
  return new Promise((reslove) => {
    let params = {
      company_id: webId.value,
      type: "index",
    };
    proxy.$axios.post("/Index/getGames", params).then((res) => {
      if (res.code == 200) {
        gameList.value = res.data;
        reslove();
      }
    });
  });
};
// 跳转页面
const handleGo = (val, n) => {
  proxy.$router.push({
    path: val,
  });
  store.commit("setActiveIndex", n);
};
// 滑动监测
const handleScroll = () => {
  const containerHeight = window.innerHeight;
  const scrollPosition = document.documentElement.scrollTop;
  if (scrollPosition <= scrollPosition1.value) {
    return;
  }
  scrollPosition1.value = scrollPosition;
  // 假设您有一个元素的ID为'myElement'
  const element = document.getElementById("myElement");
  const wHeight = element.offsetHeight;
  if (
    navigator.userAgent.match(/Mobi/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i)
  ) {
    if (
      scrollPosition >= 0 &&
      scrollPosition <= (wHeight - containerHeight) * 0.5
    ) {
      styleKey1.value = styleKey1.value + 1;
    } else if (
      scrollPosition > (wHeight - containerHeight) * 0.5 &&
      scrollPosition <= (wHeight - containerHeight) * 0.8
    ) {
      styleKey2.value = styleKey2.value + 1;
    } else if (scrollPosition > (wHeight - containerHeight) * 0.8) {
      styleKey3.value = styleKey3.value + 1;
    }
  } else {
    if (
      scrollPosition >= 0 &&
      scrollPosition <= (wHeight - containerHeight) * 0.3
    ) {
      styleKey1.value = styleKey1.value + 1;
    } else if (
      scrollPosition > (wHeight - containerHeight) * 0.3 &&
      scrollPosition <= (wHeight - containerHeight) * 0.6
    ) {
      styleKey2.value = styleKey2.value + 1;
    } else if (scrollPosition > (wHeight - containerHeight) * 0.6) {
      if ($index.value == 5) {
        return;
      }
      styleKey3.value = styleKey3.value + 1;
    }
  }
};
// 鼠标滑动监测
const handleWheel = (e) => {
  e.preventDefault();
  if (canRun.value) {
    canRun.value = false;
    goScroll(e);
    setTimeout(() => {
      canRun.value = true;
    }, 500);
  }
};
const goScroll = (e) => {
  //e.wheelDelta 用来判断上一个下一个 <0 下一个 >0上一个
  if (e.wheelDelta < 0) {
    next();
  } else {
    last();
  }
};
// 下一个
const next = () => {
  if ($index.value < 4) {
    var element = document.getElementById(`section${$index.value}`);
    // 获取元素的总高度（包括内边距、边框和滚动条）
    var totalHeight = element.offsetHeight;
    window.scrollBy({ top: totalHeight, behavior: "smooth" });
    $index.value++;
    proxy.$router.replace({
      path: "/",
      query: { page: $index.value },
    });
  } else if ($index.value == 4) {
    $index.value++;
    window.scrollBy({ top: 200, behavior: "smooth" });
    proxy.$router.replace({
      path: "/",
      query: { page: $index.value },
    });
  }
};
// 上一个
const last = () => {
  if ($index.value == 5) {
    $index.value--;
    window.scrollBy({ top: -200, behavior: "smooth" });
    proxy.$router.replace({
      path: "/",
      query: { page: $index.value },
    });
    return;
  }
  if ($index.value > 1) {
    $index.value--;
    proxy.$router.replace({
      path: "/",
      query: { page: $index.value },
    });
    var element = document.getElementById(`section${$index.value}`);
    // 获取元素的总高度（包括内边距、边框和滚动条）
    var totalHeight = element.offsetHeight;
    window.scrollBy({ top: -totalHeight, behavior: "smooth" });
  }
};
const scrollToAnchor = (id) => {
  console.log(id);
  document.querySelector(id).scrollIntoView({ behavior: "smooth" });
};
onMounted(async () => {
  window.addEventListener("scroll", handleScroll);
  window.addEventListener("wheel", handleWheel, { passive: false });
  store.commit("setLoad", false);
  await initData();
  if (
    navigator.userAgent.match(/Mobi/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i)
  ) {
    // 当前设备是移动设备
    // 设置轮播图
    const mySwiper = new Swiper(".swiper-container", {
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      centeredSlides: true,
      slidesPerView: 1,
      navigation: {
        nextEl: ".next",
        prevEl: ".prev",
      },
      // on: {
      //   click: function (e) {
      //     const val = e.target
      //     const type = val.getAttribute('data-game') // 获取绑定的game
      //     if (type) {
      //       goDetail(type)
      //     }
      //   },
      // },
    });
    mySwiper;
  } else {
    // 设置轮播图
    const mySwiper = new Swiper(".swiper-container", {
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      centeredSlides: true,
      slidesPerView: 2,
      navigation: {
        nextEl: ".next",
        prevEl: ".prev",
      },
      // on: {
      //   click: function (e) {
      //     const val = e.target
      //     const type = val.getAttribute('data-game') // 获取绑定的game
      //     if (type) {
      //       goDetail(type)
      //     }
      //   },
      // },
    });
    mySwiper;
  }
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
  window.removeEventListener("wheel", handleWheel);
});
</script>
<style src="./home.css"></style>
<style scoped>
</style>
