<template>
  <div class="auto_css" id="myElement">
    <div class="section1 section" id="section1">
      <img class="aboutbg1 pc" :src="require('@/assets/img/autobg1.png')" />
      <img class="aboutbg1 h5" :src="require('@/assets/imgh5/autobg1.png')" />

      <div class="top-font">关于我们</div>
      <div class="ct-font">
        <div>YUCHUANG</div>
        <div>CENTURY</div>
      </div>
    </div>
    <div class="section2 section" id="section2">
      <img class="aboutbg2 pc" :src="require('@/assets/img/autobg2.png')" />
      <img class="aboutbg2 h5" :src="require('@/assets/imgh5/autobg2.png')" />

      <div class="contain">
        <div class="top-btns">
          <div
            class="top-btn"
            :class="type == 1 ? 'active-btn' : ''"
            @click="handleChange(1)"
          >
            公司简介
          </div>
          <!-- <div
            class="top-btn"
            :class="type == 2 ? 'active-btn' : ''"
            @click="handleChange(2)"
          >
            企业文化
          </div> -->
          <div
            class="top-btn"
            :class="type == 3 ? 'active-btn' : ''"
            @click="handleChange(3)"
          >
            发展历程
          </div>
        </div>

        <!-- 公司简介  -->
        <div class="center" v-if="type == 1">
          <img class="c-bg pc" :src="require('@/assets/img/containbg.png')" />
          <img class="c-bg h5" :src="require('@/assets/imgh5/containbg.png')" />

          <!-- 左边 -->
          <img class="a-name" :src="require('@/assets/img/alogo.png')" />
          <div class="a-text">
            <p>
              浙江宇创世纪科技股份有限公司，于 2022
              年成功转型进军游戏领域。公司秉持创新为先、品质至上的经营理念，汇聚了行业内众多优秀的技术开发人才。
            </p>
            <p>
              短短时间内，已打造出多款爆款游戏产品， 2023
              年推出的《八荒主宰》、《傲天绝剑》，以及 2024
              年的《狂暴西游》、《齐天大战神》。这些游戏备受玩家青睐，多款产品流水过亿，展现出强大的市场竞争力和影响力。未来，宇创世纪将继续砥砺前行，为玩家带来更多精彩的游戏体验。
            </p>
          </div>

          <!-- 右边 -->
          <div class="a-pic pc">
            <img class="a-picimg" :src="require('@/assets/img/apic.png')" />
          </div>
        </div>
        <!-- 企业文化  -->
        <div class="center" v-if="type == 2">
          <img class="c-bg pc" :src="require('@/assets/img/containbg.png')" />
          <img class="c-bg h5" :src="require('@/assets/imgh5/containbg.png')" />

          <!-- 右边 -->
          <img class="a-name2" :src="require('@/assets/img/qwlogo.png')" />
          <div class="a-text2">
            <p>
              浙江宇创世纪科技股份有限公司，于 2022
              年成功转型进军游戏领域。公司秉持创新为先、品质至上的经营理念，汇聚了行业内众多优秀的技术开发人才。
            </p>
            <p>
              短短时间内，已打造出多款爆款游戏产品， 2023
              年推出的《八荒主宰》、《傲天绝剑》，以及 2024
              年的《狂暴西游》、《齐天大战神》。这些游戏备受玩家青睐，多款产品流水过亿，展现出强大的市场竞争力和影响力。未来，宇创世纪将继续砥砺前行，为玩家带来更多精彩的游戏体验。
            </p>
          </div>

          <!-- 左边 -->
          <div class="a-pic2 pc">
            <img class="a-picimg" :src="require('@/assets/img/apic.png')" />
          </div>
        </div>
        <!-- 发展历程  -->
        <div class="time-center" v-if="type == 3">
          <!-- pc -->
          <img class="lineimg pc" :src="require('@/assets/img/timeline.png')" />
          <div class="line1 line pc">
            <div class="years">2022</div>
            <img class="lineimg" :src="require('@/assets/img/timeup.png')" />
            <div class="line-text">转型进军游戏领域</div>
          </div>
          <div class="line2 line pc">
            <div class="years">2023</div>
            <img class="lineimg" :src="require('@/assets/img/timedown.png')" />
            <div class="line-text">
              成立2大工作室群（傲天、齐天）， 并推出的《八荒主宰》、《傲天绝剑》
            </div>
          </div>
          <div class="line3 line pc">
            <div class="years">2024</div>
            <img class="lineimg" :src="require('@/assets/img/timeup.png')" />
            <div class="line-text">《狂暴西游》、《齐天大战神》</div>
          </div>
          <!-- h5  -->
          <img
            class="lineimg h5"
            :src="require('@/assets/imgh5/timeline.png')"
          />
          <div class="line1 line h5">
            <div class="years">2022</div>
            <img
              class="lineimgh5"
              :src="require('@/assets/imgh5/timebg1.png')"
            />
            <div class="line-text">转型进军游戏领域</div>
          </div>
          <div class="line2 line h5">
            <div class="years">2023</div>
            <img
              class="lineimgh5"
              :src="require('@/assets/imgh5/timebg2.png')"
            />
            <div class="line-text">
              成立2大工作室群（傲天、齐天）， 并推出的《八荒主宰》、《傲天绝剑》
            </div>
          </div>
          <div class="line3 line h5">
            <div class="years">2024</div>
            <img
              class="lineimgh5"
              :src="require('@/assets/imgh5/timebg3.png')"
            />
            <div class="line-text">《狂暴西游》、《齐天大战神》</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, computed, watch, onMounted } from "vue";
const { proxy } = getCurrentInstance();
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
// 按钮类型
const type1 = computed(() => proxy.$route.query.aboutType || 1);
if (!type1.value) {
  type1.value = 1;
}
const type = ref(type1.value);
watch(type1, (newV) => {
  type.value = newV;
});

const handleChange = (val) => {
  type.value = val;
  // console.log(val, 44);
};
onMounted(async () => {
  store.commit("setLoad", false);
});
</script>
<style src="./auto.css" scoped></style>
<style scoped>
</style>
